'use client'

import {
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  FcAssistant,
  FcCollaboration,
  FcDonate,
  FcManager,
} from 'react-icons/fc'


const Card = ({ heading, description, icon, href }) => {
  return (
    <Box
      maxW={{ base: 'full', md: '275px' }}
      w={'full'}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}>
      <Stack align={'start'} spacing={2}>
        <Flex
          w={16}
          h={16}
          align={'center'}
          justify={'center'}
          color={'white'}
          rounded={'full'}
          bg={useColorModeValue('gray.100', 'gray.700')}>
          {icon}
        </Flex>
        <Box mt={2}>
          <Heading size="md">{heading}</Heading>
          <Text mt={1} fontSize={'sm'}>
            {description}
          </Text>
        </Box>
      </Stack>
    </Box>
  )
}

const FeatureList = () => {
  return (
    <Box marginTop="2rem" p={4} bgGradient={`linear(to-l, #2f3d52, #101920, #16202a)`} color="white" >
      <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>
          Why Choose Us?
        </Heading>
        <Text fontSize={{ base: 'sm', sm: 'lg' }}>
          Discover the home of your dreams with eZeeAcres. As specialists in residential real estate, we bring a wealth of local market knowledge to ensure your property journey is smooth and successful.
        </Text>
      </Stack>

      <Box maxW={'10xl'} mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          <Card
            heading={'Local Market Insight'}
            icon={<Icon as={FcAssistant} w={10} h={10} />}
            description={'Benefit from our in-depth understanding of the local market, providing you with valuable insights for informed decisions.'}
            href={'#'}
          />
          <Card
            heading={'Personalized Service'}
            icon={<Icon as={FcCollaboration} w={10} h={10} />}
            description={"Experience unparalleled customer service tailored to your unique needs, whether you're buying, selling, or investing."}
            href={'#'}
          />
          <Card
            heading={'Diverse Property Options'}
            icon={<Icon as={FcDonate} w={10} h={10} />}
            description={'Explore a wide range of lands, plots, and independent houses, each carefully curated to suit your preferences.'}
            href={'#'}
          />
          <Card
            heading={'Integrity Matters'}
            icon={<Icon as={FcManager} w={10} h={10} />}
            description={'Trust us for transparent transactions and a commitment to your satisfaction. Your dream home awaits with eZeeAcres.'}
            href={'#'}
          />
        </Flex>
      </Box>
    </Box>
  )
}

export default FeatureList;