import {
  Box,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { seo, MAX_WIDTH } from "./../config";
import { Helmet } from "react-helmet";
import FeatureList from "../components/FeatureList";

const Home = () => {
  const color = useColorModeValue("telegram.500", "telegram.400");

  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.img} />
        <meta property="og:url" content={seo.canonical} />
        <meta property="og:type" content="website" />
      </Helmet>
      <Box
        as="section"
        d="flex"
        alignItems="center"
        justifyContent="center" // Add this line to horizontally center the inner content
        flexDir="column"
        textAlign="center"
        py="4"
        maxW={MAX_WIDTH}
        marginInline="auto"
      >
        <Box>
          <video
            style={{
              objectFit: "cover",
              height: "400px",
              width: "100%"
            }}
            autoPlay
            muted
            loop
          >
            <source src="/landingVideo.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <Heading mt="5" fontSize={["3xl", "4xl"]} fontWeight="700">
            <Text as="span" color={color}>
            Building Trust 
            </Text>{" "}
            in Every Acre.
          </Heading>
          <Heading mt="1" fontSize={["2xl", "3xl"]} fontWeight="700">
            Explore the Beauty of 
            {" "}<Text as="span" color={color}>
            Plots, and Independent  
            </Text> Houses.
          </Heading>
          <Text py="4">
            Where expertise meets excellence – specializing in making your{" "}
            <Text as="span" fontWeight="600">
            residential dreams 
            </Text>{" "} a reality!
          </Text>
          <Button
            colorScheme="telegram"
            variant="outline"
            size="lg"
            fontSize="20px"
          >
            Reach Out to Us
          </Button>
        </Box>
      </Box>
      <FeatureList/>
    </>
  );
};

export default Home;
