import { Image } from "@chakra-ui/react";
import { HORIZONTAL_LOGO } from "../config";

const Logo = () => {
    return (
        <Image src={HORIZONTAL_LOGO} maxH="50px" />
    );
};

export default Logo;
