export const seo = {
    title: "eZeeAcres",
    description: "Discover the home of your dreams with eZeeAcres. As specialists in residential real estate, we bring a wealth of local market knowledge to ensure your property journey is smooth and successful.",
    canonical: "https://ezeeacres.com/",
    img:"https://firebasestorage.googleapis.com/v0/b/portfolio-1b3d4.appspot.com/o/ezeeacres_nobg.png?alt=media&token=79702c19-56fb-4187-9c2a-17fc65c1752f",
};

export const MAX_WIDTH = "1440px";
export const HORIZONTAL_LOGO = "https://firebasestorage.googleapis.com/v0/b/portfolio-1b3d4.appspot.com/o/ezeeacres_horizontal_logo.png?alt=media&token=b016b1c0-7d88-4a32-8b67-7acd68f46644";
export const TRANSPARENT_LOGO = "https://firebasestorage.googleapis.com/v0/b/portfolio-1b3d4.appspot.com/o/ezeeacres_nobg.png?alt=media&token=79702c19-56fb-4187-9c2a-17fc65c1752f";
