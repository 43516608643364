import React from 'react';
import {
  Box,
  ChakraProvider,
  Stack,
  theme
} from '@chakra-ui/react';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from "./pages/index";
import Header from './components/Header';
import Footer from './components/Footer';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    loader: () => ({ message: "Hello useColorModeValue Router!" }),
    children: [
    ],
  },
]);

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Box pb={8}>
        <Stack
          pos="relative"
          bgGradient={`linear(to-l, #2f3d52, #101920, #16202a)`}
          height="250px"
          w="100%"
        ></Stack>
        <Box isolation="isolate" zIndex={3} mt="-12rem" marginInline="auto">
            <RouterProvider router={router} />
        </Box>
      </Box>
      <Footer/>
    </ChakraProvider>
  );
}

export default App;